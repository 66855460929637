import {
  AddAdminUserRequest,
  AddAdminUserResponse,
  AddCategoriesFromCatalogueRequest,
  AddCategoriesFromCatalogueResponse,
  AddCategoryToCatalogueRequest,
  AddCategoryToCatalogueResponse,
  AddRequirementToCatalogueRequest,
  AddRequirementToCatalogueResponse,
  AddUserToClientRequest,
  AddUserToClientResponse,
  AddUserToOrganisationRequest,
  AddUserToOrganisationResponse,
  ClientReminderRequest,
  ClientReminderResponse,
  CopyPeriodRequest,
  CopyPeriodResponse,
  DeleteCategoryFromCatalogueRequest,
  DeleteCategoryFromCatalogueResponse,
  DeleteFileRequest,
  DeleteFileResponse,
  DeleteRequirementFromCatalogueRequest,
  DeleteRequirementFromCatalogueResponse,
  DropShoeboxFileRequest,
  DropShoeboxFileResponse,
  OrderCatalogueCategoryRequest,
  OrderCatalogueCategoryResponse,
  RegisterInvitedUserRequest,
  RegisterInvitedUserResponse,
  RegisterNewUserRequest,
  RegisterNewUserResponse,
  RemoveUserFromClientRequest,
  RemoveUserFromClientResponse,
  RemoveUserFromOrganisationRequest,
  RemoveUserFromOrganisationResponse,
  ResendInviteRequest,
  ResendInviteResponse,
  UpdateOrganisationAdminRequest,
  UpdateOrganisationAdminResponse,
  WorkingPapersRequest,
  WorkingPapersResponse,
} from "@taxy/common";
import { getApp } from "firebase/app";
import { Functions, getFunctions, httpsCallable } from "firebase/functions";
import { useMemo } from "react";

export class HttpFunctions {
  constructor(readonly functions: Functions) {}

  public workingPapers = httpsCallable<WorkingPapersRequest, WorkingPapersResponse>(this.functions, "workingPapers");

  public addCategoriesFromCatalogue = httpsCallable<
    AddCategoriesFromCatalogueRequest,
    AddCategoriesFromCatalogueResponse
  >(this.functions, "addCategoriesFromCatalogue");

  public addCategoryToCatalogue = httpsCallable<AddCategoryToCatalogueRequest, AddCategoryToCatalogueResponse>(
    this.functions,
    "addCategoryToCatalogue"
  );

  public deleteCategoryFromCatalogue = httpsCallable<
    DeleteCategoryFromCatalogueRequest,
    DeleteCategoryFromCatalogueResponse
  >(this.functions, "deleteCategoryFromCatalogue");

  public addRequirementToCatalogue = httpsCallable<AddRequirementToCatalogueRequest, AddRequirementToCatalogueResponse>(
    this.functions,
    "addRequirementToCatalogue"
  );

  public deleteRequirementFromCatalogue = httpsCallable<
    DeleteRequirementFromCatalogueRequest,
    DeleteRequirementFromCatalogueResponse
  >(this.functions, "deleteRequirementFromCatalogue");

  // TODO replace call in Catalogue.tsx with this one - also needs fixing because it doesn't work properly
  public orderCatalogueCategory = httpsCallable<OrderCatalogueCategoryRequest, OrderCatalogueCategoryResponse>(
    this.functions,
    "orderCategory"
  );

  public addUserToClient = httpsCallable<AddUserToClientRequest, AddUserToClientResponse>(
    this.functions,
    "addUserToClient"
  );

  public removeUserFromClient = httpsCallable<RemoveUserFromClientRequest, RemoveUserFromClientResponse>(
    this.functions,
    "removeUserFromClient"
  );

  public addUserToOrganisation = httpsCallable<AddUserToOrganisationRequest, AddUserToOrganisationResponse>(
    this.functions,
    "addUserToOrganisation"
  );

  public addAdminUser = httpsCallable<AddAdminUserRequest, AddAdminUserResponse>(this.functions, "addAdminUser");

  public removeUserFromOrganisation = httpsCallable<
    RemoveUserFromOrganisationRequest,
    RemoveUserFromOrganisationResponse
  >(this.functions, "removeUserFromOrganisation");

  public updateOrganisationAdminUser = httpsCallable<UpdateOrganisationAdminRequest, UpdateOrganisationAdminResponse>(
    this.functions,
    "updateOrganisationAdminUser"
  );

  public registerInvitedUser = httpsCallable<RegisterInvitedUserRequest, RegisterInvitedUserResponse>(
    this.functions,
    "registerInvitedUser"
  );

  public registerNewUser = httpsCallable<RegisterNewUserRequest, RegisterNewUserResponse>(
    this.functions,
    "registerNewUser"
  );

  public dropShoeboxFile = httpsCallable<DropShoeboxFileRequest, DropShoeboxFileResponse>(
    this.functions,
    "dropShoeboxFile"
  );

  public resendInvite = httpsCallable<ResendInviteRequest, ResendInviteResponse>(this.functions, "resendInvite");

  public remindClient = httpsCallable<ClientReminderRequest, ClientReminderResponse>(this.functions, "remindClient");

  public copyPeriod = httpsCallable<CopyPeriodRequest, CopyPeriodResponse>(this.functions, "copyPeriod");

  public deleteFile = httpsCallable<DeleteFileRequest, DeleteFileResponse>(this.functions, "deleteFile");
}

const useHttpFunctions = (): HttpFunctions => {
  const functions = getFunctions(getApp(), "australia-southeast1");
  return useMemo(() => new HttpFunctions(functions), [functions]);
};

export default useHttpFunctions;
