import { DragEndEvent } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { chain, findIndex, includes, indexOf, isEmpty } from "lodash";
import { useEffect, useState } from "react";
import useClientService from "../../hooks/ClientService";
import useEntityService from "../../hooks/EntityService";
import useHttpFunctions from "../../hooks/HttpFunctions";
import { FileType, RequirementType } from "../../types";
import UploadFile from "../UploadFile";
import FileList from "../file/FileList";

type RequirementFilesProps = {
  organisationId: string;
  clientId: string;
  periodId: string;
  entityId: string;
  categoryId: string;
  subCategoryId: string | undefined;
  requirement: RequirementType;
  files: FileType[];
  onFileChange: () => void;
};

const RequirementFiles = ({
  organisationId,
  clientId,
  periodId,
  entityId,
  categoryId,
  subCategoryId,
  requirement,
  files,
  onFileChange,
}: RequirementFilesProps) => {
  const entityService = useEntityService();
  const clientService = useClientService();
  const httpFunctions = useHttpFunctions();

  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [requirementFiles, setRequirementFiles] = useState<FileType[]>([]);

  useEffect(() => {
    const { fileIds } = requirement;

    const results = chain(files)
      .filter((file) => includes(fileIds, file.fileId))
      .sortBy((file) => indexOf(fileIds, file.fileId))
      .value();

    setRequirementFiles(results);
  }, [files, requirement]);

  useEffect(() => {
    setIsEditable(requirement.status !== "COMPLETE");
  }, [requirement.status]);

  const removeFile = async (file: FileType) => {
    const { requirementId } = requirement;
    const { fileId } = file;
    await httpFunctions.deleteFile({
      organisationId,
      clientId,
      periodId,
      entityId,
      categoryId,
      subCategoryId,
      requirementId,
      fileId,
    });
    onFileChange();
  };

  const renameFile = async (file: FileType, name: string) => {
    const { fileId } = file;
    await clientService.renameFile(organisationId, clientId, periodId, fileId, name);
    onFileChange();
  };

  const togglePermanent = async (file: FileType) => {
    await clientService.togglePermanent(organisationId, clientId, periodId, file.fileId);
    onFileChange();
  };

  const reorderFile = async (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && over.id !== active.id) {
      const oldIndex = findIndex(requirementFiles, (file) => file.fileId === active.id);
      const newIndex = findIndex(requirementFiles, (file) => file.fileId === over.id);

      // immediately update the UI state to avoid a flicker while the move happens
      setRequirementFiles((previousFiles) => arrayMove(previousFiles, oldIndex, newIndex));

      // do the actual move
      await entityService.moveRequirementFile(
        organisationId,
        clientId,
        periodId,
        entityId,
        categoryId,
        subCategoryId,
        requirement.requirementId,
        oldIndex,
        newIndex
      );

      // trigger a re-load
      onFileChange();
    }
  };

  return requirement ? (
    <Box sx={{ mt: 3 }}>
      {isEditable ? (
        <Box>
          <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
            Please attach any relevant files if required
          </Typography>
          <UploadFile
            organisationId={organisationId}
            clientId={clientId}
            periodId={periodId}
            entityId={entityId}
            categoryId={categoryId}
            subCategoryId={subCategoryId}
            requirementId={requirement.requirementId}
            uploadCallback={onFileChange}
          />
        </Box>
      ) : (
        <>
          <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
            Files
          </Typography>
          {isEmpty(requirementFiles) && (
            <Typography variant="body2" color="text.secondary">
              No files
            </Typography>
          )}
        </>
      )}

      <FileList
        files={requirementFiles}
        isEditable={isEditable}
        remove={removeFile}
        rename={renameFile}
        reorder={reorderFile}
        togglePermanent={togglePermanent}
      />
    </Box>
  ) : null;
};

export default RequirementFiles;
