import { Box, DialogContentText } from "@mui/material";
import Typography from "@mui/material/Typography";
import useClientService from "../../../hooks/ClientService";
import { UserType } from "../../../types";
import ConfirmationDialog from "../../dialogs/ConfirmationDialog";

type RemoveClientUserDialogProps = {
  open: boolean;
  onClose: (message?: string) => void;
  organisationId: string;
  clientId: string;
  user: UserType;
};

const RemoveClientUserDialog = ({ open, onClose, user, organisationId, clientId }: RemoveClientUserDialogProps) => {
  const clientService = useClientService();

  const onConfirm = async () => {
    const message = await clientService.removeUserFromClient(organisationId, clientId, user.uid);
    onClose(message);
  };

  return (
    <ConfirmationDialog
      open={open}
      title="Remove user from client"
      content={
        <>
          <DialogContentText>Are you sure you want to remove the following user from this client?</DialogContentText>

          <Box sx={{ my: 4 }}>
            <Typography variant="body1">
              {user?.firstName} {user?.lastName}
            </Typography>
            <Typography variant="body1">{user?.email}</Typography>
          </Box>
        </>
      }
      onClose={() => onClose()}
      confirmButtonLabel="Remove"
      onConfirm={onConfirm}
      maxWidth="sm"
    />
  );
};

export default RemoveClientUserDialog;
