import { yupResolver } from "@hookform/resolvers/yup";
import { Autocomplete, DialogContentText, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { filter, includes, orderBy } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import useClientService from "../../../hooks/ClientService";
import { ClientType, UserType } from "../../../types";
import SubmitButton from "../../button/SubmitButton";

const formSchema = yup
  .object({
    clientId: yup.string().trim().required("Client is required"),
  })
  .required();

type FormType = yup.InferType<typeof formSchema>;

type AddToOtherClientDialogProps = {
  organisationId: string;
  user: UserType;
  open: boolean;
  onClose: (message?: string) => void;
};

const AddToAnotherClientDialog = ({ organisationId, user, open, onClose }: AddToOtherClientDialogProps) => {
  const clientService = useClientService();
  const [clients, setClients] = useState<ClientType[]>([]);

  const {
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
    reset,
    control,
    watch,
  } = useForm<FormType>({ resolver: yupResolver(formSchema), defaultValues: { clientId: "" } });

  const selectedClientId = watch("clientId");

  const onSubmit = async (data: FormType) => {
    const { clientId } = data;
    const { firstName, lastName, email } = user;
    try {
      const message = await clientService.addUserToClient(organisationId, clientId, firstName, lastName, email);
      handleClose(message);
    } catch (e: any) {
      const message = e.message || "An unexpected error has occurred";
      setError("clientId", { type: "custom", message });
    }
  };

  const handleClose = (message?: string) => {
    reset();
    onClose(message);
  };

  const loadClients = useCallback(async () => {
    const result = await clientService.getClients([organisationId]);
    const otherClients = filter(result, (client) => !includes(user.clientIds, client.clientId));
    const orderedClientsByName = orderBy(otherClients, (client) => client.name);
    setClients(orderedClientsByName);
  }, [clientService, organisationId, user]);

  useEffect(() => {
    void loadClients();
  }, [loadClients]);

  return (
    <Dialog open={open} onClose={() => handleClose()} disableRestoreFocus fullWidth maxWidth="sm">
      <DialogTitle>Add to another client</DialogTitle>

      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <DialogContentText>
            Select a client to add {user.firstName} {user.lastName} to:
          </DialogContentText>

          <Controller
            name="clientId"
            control={control}
            render={({ field }) => (
              <Autocomplete
                sx={{ my: 4 }}
                options={clients}
                getOptionLabel={(option) => option.name}
                onChange={(_, value) => field.onChange(value?.clientId || "")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select client"
                    error={!!errors.clientId}
                    helperText={errors.clientId?.message}
                    variant="outlined"
                  />
                )}
              />
            )}
          />
        </DialogContent>

        <DialogActions sx={{ m: 1 }}>
          <Button onClick={() => handleClose()} variant="outlined" color="secondary">
            Cancel
          </Button>
          <SubmitButton loading={isSubmitting} label="Add to client" disabled={!selectedClientId} />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddToAnotherClientDialog;
