import { DragEndEvent } from "@dnd-kit/core";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import OpenIcon from "@mui/icons-material/Preview";
import { Box, Paper, Table, TableBody, TableCell, TableContainer } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useState } from "react";
import SortableTableRow from "../../dnd/SortableTableRow";
import VerticalSortContainer from "../../dnd/VerticalSortContainer";
import { useApplicationState } from "../../hooks/ApplicationState";
import { FileType } from "../../types";
import startDownload from "../../utilities/startDownload";
import TaxyTooltip from "../TaxyTooltip";
import FileTypeIcon from "./FileTypeIcon";
import RenameFileDialog from "./RenameFileDialog";

type FileListProps = {
  files: FileType[];
  isEditable: boolean;
  remove: (file: FileType) => Promise<void>;
  rename: (file: FileType, fileName: string) => Promise<void>;
  reorder: (event: DragEndEvent) => void;
  togglePermanent: (file: FileType) => Promise<void>;
};

const FileList = ({ files, isEditable, remove, rename, reorder, togglePermanent }: FileListProps) => {
  const { refreshIdToken, isAccountant } = useApplicationState();

  const [selectedFile, setSelectedFile] = useState<FileType>();
  const [renameFileDialogOpen, setRenameFileDialogOpen] = useState(false);
  const [processing, setProcessing] = useState<boolean>(false);

  const handleRemoveFile = async (file: FileType) => {
    try {
      setProcessing(true);
      await remove(file);
    } finally {
      setProcessing(false);
    }
  };

  const handleTogglePermanent = async (file: FileType) => {
    try {
      setProcessing(true);
      await togglePermanent(file);
    } finally {
      setProcessing(false);
    }
  };

  const handlePreviewFile = async (file: FileType) => {
    try {
      setProcessing(true);
      await refreshIdToken();
      const newWindow = window.open(file.previewURL, "_blank", "noopener,noreferrer");
      if (newWindow) {
        newWindow.opener = null;
      }
    } finally {
      setProcessing(false);
    }
  };

  const handleDownloadFile = async (file: FileType) => {
    try {
      if (file.downloadURL) {
        setProcessing(true);
        await refreshIdToken();
        startDownload(file.downloadURL);
      }
    } finally {
      setProcessing(false);
    }
  };

  const handleRenameFile = (file: FileType) => {
    setSelectedFile(file);
    setRenameFileDialogOpen(true);
  };

  const handleRenameFileDialogClose = () => {
    setRenameFileDialogOpen(false);
    setSelectedFile(undefined);
  };

  return (
    <Box>
      <VerticalSortContainer onDragEnd={reorder} items={files.map((file) => ({ id: file.fileId }))}>
        <TableContainer component={Paper} square elevation={0}>
          <Table aria-label="File table" sx={{ ".MuiTableCell-root": { borderBottom: "none" } }} size="small">
            <TableBody>
              {files.map((file) => (
                <SortableTableRow
                  hover
                  key={file.fileId}
                  id={file.fileId}
                  sx={{ cursor: "pointer" }}
                  selected={file.fileId === selectedFile?.fileId}
                  onClick={() => setSelectedFile(file)}
                >
                  <TableCell width="100%" sx={{ p: 0.5 }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <FileTypeIcon filename={file.name} />

                      <Box sx={{ display: "flex", flexGrow: 1 }}>
                        <TaxyTooltip title={file.name}>
                          <Box
                            component="span"
                            sx={{ pl: 1, overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}
                          >
                            {file.name}
                          </Box>
                        </TaxyTooltip>

                        {file.isPermanent && (
                          <TaxyTooltip title="Permanent files are copied over when a new financial period is created. This can be used for documents such as trust deeds etc which are referenced every year.">
                            <Box component="span" sx={{ ml: 1, color: "grey.500" }}>
                              (permanent file)
                            </Box>
                          </TaxyTooltip>
                        )}
                      </Box>

                      <Box sx={{ display: "flex" }}>
                        {/* MARK AS PERMANENT */}
                        {isAccountant && (
                          <TaxyTooltip title={file.isPermanent ? "Unmark as permanent" : "Mark as permanent"}>
                            <Box>
                              <IconButton onClick={() => handleTogglePermanent(file)} disabled={processing}>
                                {file.isPermanent ? <LockIcon /> : <LockOpenIcon />}
                              </IconButton>
                            </Box>
                          </TaxyTooltip>
                        )}

                        {/* RENAME */}
                        {isEditable ? (
                          // permanent files can only be renamed by accountants
                          file.isPermanent ? (
                            isAccountant ? (
                              <TaxyTooltip title="Rename">
                                <Box>
                                  <IconButton onClick={() => handleRenameFile(file)} disabled={processing}>
                                    <EditIcon />
                                  </IconButton>
                                </Box>
                              </TaxyTooltip>
                            ) : (
                              <TaxyTooltip title="File has been marked as permanent by your accountant and cannot be be renamed">
                                <Box>
                                  <IconButton disabled>
                                    <EditIcon />
                                  </IconButton>
                                </Box>
                              </TaxyTooltip>
                            )
                          ) : (
                            <TaxyTooltip title="Rename">
                              <Box>
                                <IconButton onClick={() => handleRenameFile(file)} disabled={processing}>
                                  <EditIcon />
                                </IconButton>
                              </Box>
                            </TaxyTooltip>
                          )
                        ) : (
                          <TaxyTooltip title="File cannot be renamed because the requirement is approved">
                            <Box>
                              <IconButton disabled>
                                <EditIcon />
                              </IconButton>
                            </Box>
                          </TaxyTooltip>
                        )}

                        {/* DOWNLOAD */}
                        {file.downloadURL ? (
                          <TaxyTooltip title="Download">
                            <Box>
                              <IconButton onClick={() => handleDownloadFile(file)} disabled={processing}>
                                <DownloadIcon />
                              </IconButton>
                            </Box>
                          </TaxyTooltip>
                        ) : (
                          <TaxyTooltip title="Download unavailable">
                            <Box>
                              <IconButton disabled>
                                <DownloadIcon />
                              </IconButton>
                            </Box>
                          </TaxyTooltip>
                        )}

                        {/* PREVIEW */}
                        {file.previewURL ? (
                          <TaxyTooltip title="Preview">
                            <Box>
                              <IconButton onClick={() => handlePreviewFile(file)} disabled={processing}>
                                <OpenIcon />
                              </IconButton>
                            </Box>
                          </TaxyTooltip>
                        ) : (
                          <TaxyTooltip title="Preview unavailable">
                            <Box>
                              <IconButton disabled>
                                <OpenIcon />
                              </IconButton>
                            </Box>
                          </TaxyTooltip>
                        )}

                        {/* DELETE */}
                        {isEditable ? (
                          // permanent files can only be deleted by accountants
                          file.isPermanent ? (
                            isAccountant ? (
                              <TaxyTooltip title="Delete">
                                <Box>
                                  <IconButton onClick={() => handleRemoveFile(file)} disabled={processing}>
                                    <DeleteIcon />
                                  </IconButton>
                                </Box>
                              </TaxyTooltip>
                            ) : (
                              <TaxyTooltip title="File has been marked as permanent by your accountant and cannot be be deleted">
                                <Box>
                                  <IconButton disabled>
                                    <DeleteIcon />
                                  </IconButton>
                                </Box>
                              </TaxyTooltip>
                            )
                          ) : (
                            <TaxyTooltip title="Delete">
                              <Box>
                                <IconButton onClick={() => handleRemoveFile(file)} disabled={processing}>
                                  <DeleteIcon />
                                </IconButton>
                              </Box>
                            </TaxyTooltip>
                          )
                        ) : (
                          <TaxyTooltip title="File cannot be deleted because the requirement is approved">
                            <Box>
                              <IconButton disabled>
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          </TaxyTooltip>
                        )}
                      </Box>
                    </Box>
                  </TableCell>
                </SortableTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </VerticalSortContainer>

      {selectedFile && (
        <RenameFileDialog
          open={renameFileDialogOpen}
          onClose={handleRenameFileDialogClose}
          file={selectedFile}
          rename={rename}
        />
      )}
    </Box>
  );
};

export default FileList;
