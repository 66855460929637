import { doc, DocumentReference, orderBy, query, serverTimestamp, Timestamp, UpdateData } from "firebase/firestore";
import { orderBy as _orderBy, findIndex, map } from "lodash";
import { useMemo } from "react";
import { EntityEnum } from "../enums";
import { CategoryType, EntityType, RequirementType } from "../types";
import useCatalogueService, { CatalogueService } from "./CatalogueService";
import useFirestoreService, { FirestoreService } from "./FirestoreService";
import useHttpFunctions, { HttpFunctions } from "./HttpFunctions";

export class EntityService {
  constructor(
    readonly firestoreService: FirestoreService,
    readonly catalogueService: CatalogueService,
    readonly httpFunctions: HttpFunctions
  ) {}

  public async createEntity(
    organisationId: string,
    clientId: string,
    periodId: string,
    name: string,
    entityType: EntityEnum,
    dueDate?: Date | null
  ): Promise<void> {
    const entitiesCollection = this.firestoreService.getEntitiesCollection(organisationId, clientId, periodId);
    const entityDocument = doc(entitiesCollection);
    const entityId = entityDocument.id;
    await this.firestoreService.createDocument(entityDocument, {
      entityId,
      entityType,
      name,
      createdTimestamp: serverTimestamp(),
      dueDate: dueDate ? Timestamp.fromDate(dueDate) : null,
    });
  }

  public async updateEntity(
    organisationId: string,
    clientId: string,
    periodId: string,
    entityId: string,
    name: string,
    dueDate?: Date | null
  ) {
    const documentReference = this.firestoreService.getEntityDocument(organisationId, clientId, periodId, entityId);
    const documentSnapshot = await this.firestoreService.getDocumentSnapshot(documentReference);
    if (documentSnapshot.exists()) {
      await this.firestoreService.updateDocument(documentReference, {
        name,
        dueDate: dueDate ? Timestamp.fromDate(dueDate) : null,
      });
    }
  }

  public watchEntities(organisationId: string, clientId: string, periodId: string, callback: () => void) {
    const collectionReference = this.firestoreService.getEntitiesCollection(organisationId, clientId, periodId);
    return this.firestoreService.watchCollection(collectionReference, callback);
  }

  public async getEntities(organisationId: string, clientId: string, periodId: string): Promise<EntityType[]> {
    const entitiesCollection = this.firestoreService.getEntitiesCollection(organisationId, clientId, periodId);
    const snapshots = await this.firestoreService.getDocs(entitiesCollection);
    const entities = snapshots.docs.map((snapshot) => snapshot.data() as EntityType);

    return _orderBy(entities, (entity) => entity.createdTimestamp, "desc");
  }

  // Get the categories, requirements, and any nested sub-categories and requirements for the given entity.
  public async getCategories(
    organisationId: string,
    clientId: string,
    periodId: string,
    entityId: string
  ): Promise<CategoryType[]> {
    const categoriesCollection = this.firestoreService.getCategoriesCollection(
      organisationId,
      clientId,
      periodId,
      entityId
    );
    const categoriesSnapshot = await this.firestoreService.getDocs(query(categoriesCollection, orderBy("order")));
    return Promise.all(
      categoriesSnapshot.docs.map(async (categorySnapshot) => {
        const categoryId = categorySnapshot.id;

        const [requirements, subCategories] = await Promise.all([
          this.getRequirements(organisationId, clientId, periodId, entityId, categoryId),
          this.getSubCategories(organisationId, clientId, periodId, entityId, categoryId),
        ]);

        const data = categorySnapshot.data();

        return {
          id: data.categoryId,
          ...data,
          requirements,
          categories: subCategories,
        } as CategoryType;
      })
    );
  }

  public async addCategory(
    organisationId: string,
    clientId: string,
    periodId: string,
    entityId: string,
    parentCategoryId: string | undefined,
    categoryName: string
  ) {
    const categoriesCollection = this.firestoreService.getCategoriesCollection(
      organisationId,
      clientId,
      periodId,
      entityId,
      parentCategoryId
    );
    const count = await this.firestoreService.countDocs(categoriesCollection);
    const categoryDocument = doc(categoriesCollection);
    await this.firestoreService.createDocument(categoryDocument, {
      categoryId: categoryDocument.id,
      name: categoryName,
      createdTimestamp: serverTimestamp(),
      order: count,
    });
  }

  public async editCategory(
    organisationId: string,
    clientId: string,
    periodId: string,
    entityId: string,
    categoryId: string,
    subCategoryId: string | undefined,
    categoryName: string
  ) {
    const categoryDocument = this.firestoreService.getCategoryDocument(
      organisationId,
      clientId,
      periodId,
      entityId,
      categoryId,
      subCategoryId
    );
    const categorySnapshot = await this.firestoreService.getDocumentSnapshot(categoryDocument);
    if (categorySnapshot.exists()) {
      const update: UpdateData<CategoryType> = {
        name: categoryName,
        modifiedTimestamp: serverTimestamp(),
      };
      await this.firestoreService.updateDocument(categoryDocument, update);
    } else {
      throw new Error("Error editing category. Category does not exist.");
    }
  }

  public async moveCategory(
    organisationId: string,
    clientId: string,
    periodId: string,
    entityId: string,
    from: number,
    to: number
  ) {
    const categoriesCollection = this.firestoreService.getCategoriesCollection(
      organisationId,
      clientId,
      periodId,
      entityId
    );
    const categoriesSnapshot = await this.firestoreService.getDocs(query(categoriesCollection, orderBy("order")));

    // construct a list of document ids
    const ids = map(categoriesSnapshot.docs, (snapshot) => snapshot.id);
    const element = ids[from];

    if (element) {
      // remove the element at the 'from' position and splice it into the 'to' position
      ids.splice(from, 1);
      ids.splice(to, 0, element);

      // update the order of all categories in a transaction
      await this.firestoreService.inTransaction(async (transaction) => {
        await Promise.all(
          map(categoriesSnapshot.docs, (snapshot) => {
            const index = findIndex(ids, (id) => id === snapshot.id);
            transaction.update(snapshot.ref, { order: index });
          })
        );
      });
    }
  }

  public async addCategoriesFromCatalogue(
    organisationId: string,
    clientId: string,
    periodId: string,
    entityId: string,
    parentCategoryId: string | undefined,
    catalogueId: string,
    categoryIds: string[]
  ) {
    return this.httpFunctions.addCategoriesFromCatalogue({
      organisationId,
      clientId,
      periodId,
      entityId,
      parentCategoryId,
      catalogueId,
      categoryIds,
    });
  }

  // delete a category or sub-category
  public async deleteCategory(
    organisationId: string,
    clientId: string,
    periodId: string,
    entityId: string,
    categoryId: string,
    subCategoryId?: string
  ) {
    const documentsToDelete = await this.getDocumentsInCategory(
      organisationId,
      clientId,
      periodId,
      entityId,
      categoryId,
      subCategoryId
    );

    // delete everything
    await this.firestoreService.inTransaction(async (transaction) => {
      documentsToDelete.map((documentReference) => {
        return transaction.delete(documentReference);
      });
    });

    // update the order on the remaining categories
    const categoriesCollection = this.firestoreService.getCategoriesCollection(
      organisationId,
      clientId,
      periodId,
      entityId,
      subCategoryId ? categoryId : undefined
    );
    const categoriesSnapshot = await this.firestoreService.getDocs(query(categoriesCollection, orderBy("order")));
    await this.firestoreService.inTransaction(async (transaction) => {
      await Promise.all(
        map(categoriesSnapshot.docs, (snapshot, index) => {
          transaction.update(snapshot.ref, { order: index });
        })
      );
    });
  }

  public async moveRequirement(
    organisationId: string,
    clientId: string,
    periodId: string,
    entityId: string,
    categoryId: string,
    subCategoryId: string | undefined,
    from: number,
    to: number
  ) {
    const requirementsCollection = this.firestoreService.getRequirementsCollection(
      organisationId,
      clientId,
      periodId,
      entityId,
      categoryId,
      subCategoryId
    );
    const requirementsSnapshot = await this.firestoreService.getDocs(query(requirementsCollection, orderBy("order")));

    // construct a list of document ids
    const ids = map(requirementsSnapshot.docs, (snapshot) => snapshot.id);
    const element = ids[from];

    if (element) {
      // remove the element at the 'from' position and splice it into the 'to' position
      ids.splice(from, 1);
      ids.splice(to, 0, element);

      // update the order of all requirements in a transaction
      await this.firestoreService.inTransaction(async (transaction) => {
        await Promise.all(
          map(requirementsSnapshot.docs, (snapshot) => {
            const index = findIndex(ids, (id) => id === snapshot.id);
            transaction.update(snapshot.ref, { order: index });
          })
        );
      });
    }
  }

  public async moveRequirementFile(
    organisationId: string,
    clientId: string,
    periodId: string,
    entityId: string,
    categoryId: string,
    subCategoryId: string | undefined,
    requirementId: string,
    from: number,
    to: number
  ) {
    const requirementDocument = this.firestoreService.getRequirementDocument(
      organisationId,
      clientId,
      periodId,
      entityId,
      categoryId,
      subCategoryId,
      requirementId
    );

    const requirementSnapshot = await this.firestoreService.getDocumentSnapshot(requirementDocument);

    if (!requirementSnapshot.exists()) {
      throw new Error("Error moving requirement file. file does not exist.");
    }

    const requirement = requirementSnapshot.data() as RequirementType;

    // construct a list of document ids
    const ids = requirement.fileIds;
    const element = ids[from];

    if (element) {
      // remove the element at the 'from' position and splice it into the 'to' position
      ids.splice(from, 1);
      ids.splice(to, 0, element);

      // update the order of all categories in a transaction
      await this.firestoreService.inTransaction(async (transaction) => {
        transaction.update(requirementSnapshot.ref, { fileIds: ids });
      });
    }
  }

  private async getSubCategories(
    organisationId: string,
    clientId: string,
    periodId: string,
    entityId: string,
    categoryId: string
  ): Promise<CategoryType[]> {
    const subCategoriesCollection = this.firestoreService.getCategoriesCollection(
      organisationId,
      clientId,
      periodId,
      entityId,
      categoryId
    );
    const subCategoriesSnapshot = await this.firestoreService.getDocs(query(subCategoriesCollection, orderBy("order")));
    return Promise.all(
      subCategoriesSnapshot.docs.map(async (subCategorySnapshot) => {
        const subCategoryId = subCategorySnapshot.id;
        const subCategoryRequirements = await this.getRequirements(
          organisationId,
          clientId,
          periodId,
          entityId,
          categoryId,
          subCategoryId
        );

        const data = subCategorySnapshot.data();

        return {
          id: data.categoryId,
          ...data,
          requirements: subCategoryRequirements,
          categories: [] as CategoryType[],
        } as CategoryType;
      })
    );
  }

  private async getRequirements(
    organisationId: string,
    clientId: string,
    periodId: string,
    entityId: string,
    categoryId: string,
    subCategoryId?: string
  ): Promise<RequirementType[]> {
    const requirementsCollection = this.firestoreService.getRequirementsCollection(
      organisationId,
      clientId,
      periodId,
      entityId,
      categoryId,
      subCategoryId
    );
    const requirementsSnapshot = await this.firestoreService.getDocs(query(requirementsCollection, orderBy("order")));
    return requirementsSnapshot.docs.map((requirementSnapshot) => {
      return this.firestoreService.toDocument<RequirementType>(requirementSnapshot);
    });
  }

  // get a list of all the documents in the given category or sub-category
  private async getDocumentsInCategory(
    organisationId: string,
    clientId: string,
    periodId: string,
    entityId: string,
    categoryId: string,
    subCategoryId?: string
  ): Promise<DocumentReference[]> {
    const documentReferences: DocumentReference[] = [];

    const categoryDocument = this.firestoreService.getCategoryDocument(
      organisationId,
      clientId,
      periodId,
      entityId,
      categoryId,
      subCategoryId
    );
    const categorySnapshot = await this.firestoreService.getDocumentSnapshot(categoryDocument);

    if (!categorySnapshot.exists()) {
      throw new Error("Error deleting category. Category does not exist.");
    }

    // add the category to the list of documents to delete
    documentReferences.push(categorySnapshot.ref);

    // add each requirement to the list of documents
    const requirementsCollection = this.firestoreService.getRequirementsCollection(
      organisationId,
      clientId,
      periodId,
      entityId,
      categoryId,
      subCategoryId
    );
    const requirementsSnapshots = await this.firestoreService.getDocs(requirementsCollection);
    requirementsSnapshots.docs.forEach((requirementSnapshot) => {
      documentReferences.push(requirementSnapshot.ref);
    });

    // only look for sub-categories if we're not already in a sub-category
    if (!subCategoryId) {
      const subCategoriesCollection = this.firestoreService.getCategoriesCollection(
        organisationId,
        clientId,
        periodId,
        entityId,
        categoryId
      );
      const subCategorySnapshots = await this.firestoreService.getDocs(subCategoriesCollection);

      // add each sub-category to the list of documents
      await Promise.all(
        subCategorySnapshots.docs.map(async (subCategorySnapshot) => {
          documentReferences.push(subCategorySnapshot.ref);

          // add each requirement in the sub-category to the list of documents
          const subCategoryRequirementsCollection = this.firestoreService.getRequirementsCollection(
            organisationId,
            clientId,
            periodId,
            entityId,
            categoryId,
            subCategorySnapshot.id
          );
          const subCategoryRequirementSnapshots = await this.firestoreService.getDocs(
            subCategoryRequirementsCollection
          );
          subCategoryRequirementSnapshots.docs.forEach((subCategoryRequirementSnapshot) =>
            documentReferences.push(subCategoryRequirementSnapshot.ref)
          );
        })
      );
    }

    return documentReferences;
  }
}

const useEntityService = () => {
  const firestoreService = useFirestoreService();
  const catalogueService = useCatalogueService();
  const httpFunctions = useHttpFunctions();

  return useMemo(
    () => new EntityService(firestoreService, catalogueService, httpFunctions),
    [firestoreService, catalogueService, httpFunctions]
  );
};

export default useEntityService;
